import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, find, head } from '../../lib/nodash';
import { Box } from 'grommet';

import ProductCard from '../Product/ProductCard';
// import ImageContentModule from '../DynamicPage/ImageContentModule';

const EntityCollectionImage = ({ collection, product, entityType, size }) => {
  const source = entityType === 'collection' ? collection : product;
  const tags = get(
    'value',
    find(
      { namespace: 'accentuate', key: 'tags' },
      get('metafields', collection)
    )
  ).split('|');
  const image =
    entityType === 'collection'
      ? head(
          JSON.parse(
            get(
              'value',
              find({ key: 'collection_image' }, get('metafields', source))
            )
          )
        )
      : undefined;

  return (
    <Box width={size}>
      <ProductCard
        tags={tags}
        collectionImageUrl={get('original_src', image)}
      />
      {/* <ImageContentModule image={image} /> */}
    </Box>
  );
};

EntityCollectionImage.propTypes = {
  collection: PropTypes.object,
  product: PropTypes.object,
  entityType: PropTypes.oneOf(['collection', 'product']),
  size: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
    'auto',
  ]),
};

export default memo(EntityCollectionImage);
